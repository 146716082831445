<template>
  <div id="travelOrdersContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Obrazci</li>
    </vs-breadcrumb>


    <vs-tabs v-model="activeTab">
      <vs-tab label="Prijava napake v IT sistemu">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSd7WD3LuewGAjg7XOSmLd5FOBgx2cg7teUotLbp94BUsrskmQ/viewform?embedded=true"
          frameBorder="0" style="width: 100%; min-height: 100vh" allowFullScreen="true"></iframe>
      </vs-tab>
      <vs-tab label="Prijava pobude za nadgradnjo IT sistema">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfx2S1ThG2s9NVGmwe7J_TixSU5nwGtakk-EjXKFtOPabggmg/viewform?embedded=true"
          frameBorder="0" style="width: 100%; min-height: 100vh" allowFullScreen="true"></iframe>
      </vs-tab>
      <vs-tab label="Prijava pobudbe za medijsko objavo">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdMie8p69tI5E3LEPyrzGGMV8gScaHwHB4v6mSGljpy6VWGJA/viewform?embedded=true"
          frameBorder="0" style="width: 100%; min-height: 100vh" allowFullScreen="true"></iframe>
      </vs-tab>
      <vs-tab label="Prijava predloga za novosti pri delu s prostovoljci in starostniki">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeXMp2GDSVWfXZHxpLIEGVOSzn5Np8WZRCjIjOjrQ0YqUlBjQ/viewform?embedded=true"
          frameBorder="0" style="width: 100%; min-height: 100vh" allowFullScreen="true"></iframe>
      </vs-tab>
    </vs-tabs>


    <div id="parentx">

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import jwt from "jsonwebtoken";

export default {
  name: 'travelOrders',

  components: {},

  data() {
    return {
      activeTab: 0
    }
  },

  computed: {},

  mounted() {
    const _this = this;
  },

  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave');
    const _this = this;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {

  }
}
</script>

